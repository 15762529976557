if (GLOBAL.FACING === "internal") {
    import("./internal/components/App").then(({ renderApp, router }) => {
        router.start(renderApp);
    });
}
else if (GLOBAL.FACING === "external") {
    if (GLOBAL.APP_ENV === "prod") {
        // add the DAP tracking script
        const script = document.createElement("script");
        script.async = true;
        script.id = "_fed_an_ua_tag";
        script.src = "https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=NASA&subagency=JPL-psss";
        document.head.appendChild(script);

        // redirect to psss.jpl.nasa.gov
        if (window.location.hostname !== "psss.jpl.nasa.gov") {
            window.location.replace("https://psss.jpl.nasa.gov");
        }
    }
    import("./external/components/App").then(({ renderApp, router }) => {
        router.start(renderApp);
    });
}
